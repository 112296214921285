import { paths as homePaths } from '../constants/contacts'

export const getHostElems = () => window.location.host.split('.')

export const getRedirectUrl = () => {
	let hostElems = getHostElems()
	if (hostElems.length === 2) {
		hostElems = ['www', ...hostElems]
	}
	hostElems[0] = 'subdomain'
	return `${window.location.protocol}//${hostElems.join('.')}`
}

export const getFirstSubdomain = () => window.location.hostname.split('.')[0]

export const getTenantFromLocation = () => {
	const subdomain = getFirstSubdomain()
	if (
		[
			'www',
			'help',
			'chronograph',
			'cg-staging',
			'cg',
			'auth',
			'excel',
		].includes(subdomain)
	) {
		return null
	}
	return subdomain
}

export const getAppDomain = () =>
	getRedirectUrl().split('.').slice(1).join('.').split(':')[0]

export const getAuthUrl = () => getRedirectUrl().replace('subdomain', 'auth')

export const getHomeUrl = () => getRedirectUrl().replace('subdomain', 'www')

export const getExcelUrl = () => getRedirectUrl().replace('subdomain', 'excel')

export const getAppRedirectPath = () => {
	const { pathname } = window.location
	const isHomePath = Object.values(homePaths)
		.filter(path => path !== '/')
		.some(path => pathname.startsWith(path))
	return (!isHomePath && pathname) || ''
}
