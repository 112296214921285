import { CO_ADMIN_BASE_ROUTE } from '@/constants/globalConstants'
import { LimitedPartnerUserViewModes } from '@/types/entities'
import { AnyObj } from '@/types/utils'
import toast from '@/utils/toast'
import { getAppDomain, getRedirectUrl } from 'domainUtils'
import { deleteCookie, getCookieOrLocalStorage, setCookie } from './cookieUtils'
import { getImpersonatedUser } from './impersonationUtils'
import {
	decodeToken,
	getTokenExpirationDate,
	isTokenExpired,
} from './jwtHelper'

type Roles = 'user' | 'input' | 'analyst' | 'client_admin' | 'su_admin'

export interface JwtCustomClaims {
	excel_only: boolean
	is_gp: boolean
	is_staff: boolean
	permissions: string[]
	roles: Roles[]
	tenant: string
	view_mode: LimitedPartnerUserViewModes | 'diligence' | null
}

export type SyntheticToken = Partial<JwtCustomClaims>

export interface JwtPayload {
	'https://chronograph.pe/excel_only': JwtCustomClaims['excel_only']
	'https://chronograph.pe/is_gp': JwtCustomClaims['is_gp']
	'https://chronograph.pe/is_staff': JwtCustomClaims['is_staff']
	'https://chronograph.pe/permissions': JwtCustomClaims['permissions']
	'https://chronograph.pe/roles': JwtCustomClaims['roles']
	'https://chronograph.pe/tenant': JwtCustomClaims['tenant']
	'https://chronograph.pe/view_mode': JwtCustomClaims['view_mode']
	aud: string
	email: string
	email_verified: boolean
	exp: number
	family_name: string
	given_name: string
	iat: number
	iss: string
	name: string
	nickname: string
	picture: string
	sub: string
	updated_at: string
}

class AuthService {
	static tokenCookieName = 'id_token'
	static tenantCookieName = 'subdomain'
	static jwtNamespace = 'https://chronograph.pe/'

	private _tokenPayload: JwtPayload | null
	private _syntheticToken: SyntheticToken | null

	constructor() {
		this.checkUnauthorized = this.checkUnauthorized.bind(this)
		this._tokenPayload = null
		this._syntheticToken = null
	}

	loggedIn() {
		// @ts-expect-error -- Meticulous is a global variable
		if (window.Meticulous?.isRunningAsTest) return true
		return this.tokenNotExpired() && !!this.getTenant()
	}

	/**********
	 ATTRIBUTES
	 **********/
	getTenant() {
		return this.getCustomClaim('tenant')
	}
	isGp() {
		return this.getCustomClaim('is_gp')
	}
	getClientType() {
		return this.getCustomClaim('is_gp') ? 'gp' : 'lp'
	}
	excelOnly() {
		return this.getCustomClaim('excel_only')
	}
	isStaff() {
		return this.getCustomClaim('is_staff')
	}
	actualStaffUser() {
		return this.getActualUserClaim('is_staff')
	}
	isMaster() {
		return this.getTenant() === 'admin'
	}

	getClientUrl() {
		const subdomain = this.getTenant()
		if (!subdomain) return null
		return getRedirectUrl().replace('subdomain', subdomain)
	}

	getEmail() {
		const payload = this.getTokenPayload()
		return (payload || {}).email
	}

	getDefaultPath() {
		if (this.isOnboarding()) return '/onboard'
		if (this.apiOnly()) return '/developer'
		if (this.accessMgrOnly()) return '/settings/users'
		if (this.isGp()) {
			return this.isInputUser() ? CO_ADMIN_BASE_ROUTE : '/gp/collect'
		}
		if (
			this.isMaster() ||
			(this.isStaff() && !this.isAdmin() && !this.isSuAdmin())
		) {
			return '/create'
		}
		if (this.diligenceOnly()) return '/diligence'
		return '/portfolio'
	}

	/**********
	 VIEW MODES
	 **********/
	viewMode() {
		return this.getCustomClaim('view_mode')
	}
	isOnboarding() {
		return (this.viewMode() || '').includes('onboarding')
	}
	apiOnly() {
		return this.viewMode() === 'api'
	}
	diligenceOnly() {
		return this.viewMode() === 'diligence'
	}
	accessMgrOnly() {
		return this.viewMode() === 'access_manager'
	}

	/*****
	 ROLES
	 *****/
	getRoles() {
		return this.getCustomClaim('roles') || []
	}

	hasRole(role: Roles) {
		const roles = this.getRoles()
		return roles.indexOf(role) !== -1
	}

	isUser() {
		return this.hasRole('user')
	}
	isInputUser() {
		return this.hasRole('input')
	}
	isAdmin() {
		return this.hasRole('client_admin')
	}
	isSuAdmin() {
		return this.hasRole('su_admin')
	}
	isAnalyst() {
		return this.hasRole('analyst')
	}
	isStaffAdmin() {
		return !!this.isStaff() && this.hasFullAccess()
	}
	isGpClient() {
		return !!this.isGp() && !this.isStaff()
	}

	/***********
	 PERMISSIONS
	 ***********/
	getPermissions() {
		return this.getCustomClaim('permissions') || []
	}

	hasFullAccess() {
		return this.isAdmin() || this.isSuAdmin()
	}

	hasPermission(permission: string) {
		return (
			this.hasFullAccess() || this.getPermissions().indexOf(permission) !== -1
		)
	}

	hasAnyPermission(permissions: string[]) {
		const allPermissions = this.getPermissions()
		return (
			this.hasFullAccess() ||
			permissions.some(perm => allPermissions.indexOf(perm) !== -1)
		)
	}

	hasAllPermissions(permissions: string[]) {
		const allPermissions = this.getPermissions()
		return (
			this.hasFullAccess() ||
			permissions.every(perm => allPermissions.indexOf(perm) !== -1)
		)
	}

	/***************
	 TOKEN UTILITIES
	 ***************/
	getToken() {
		return getCookieOrLocalStorage(AuthService.tokenCookieName)
	}

	getTokenPayload(forceRefresh = false) {
		if (!this._tokenPayload || forceRefresh) {
			const token = this.getToken()
			this._tokenPayload = token && decodeToken(token)
		}
		return this._tokenPayload
	}

	getCustomClaim<K extends keyof JwtCustomClaims>(claim: K) {
		const impersonatedClaim = this.getImpersonatedUserClaim(claim)
		if (impersonatedClaim !== undefined) return impersonatedClaim

		return this.getActualUserClaim(claim)
	}

	getActualUserClaim<K extends keyof JwtCustomClaims>(
		claim: K,
	): JwtCustomClaims[K] | null | undefined {
		const payload = this.getTokenPayload()
		const namespace = AuthService.jwtNamespace
		return payload && payload[namespace + claim]
	}

	getImpersonatedUserClaim<K extends keyof SyntheticToken>(
		claim: K,
	): SyntheticToken[K] {
		return (this.syntheticToken || {})[claim]
	}

	getTokenExpirationDate() {
		return getTokenExpirationDate(this.getTokenPayload())
	}

	tokenNotExpired() {
		const payload = this.getTokenPayload()
		return !!payload && !isTokenExpired(payload)
	}

	/********************
	 LOGIN/LOGOUT METHODS
	 ********************/
	setToken(idToken: string, useLocalStorage = false) {
		const { tokenCookieName } = AuthService
		const payload = decodeToken(idToken)
		const expirationDate = payload && getTokenExpirationDate(payload)
		const options: AnyObj = {}
		if (expirationDate) {
			options.expires = expirationDate
		}
		setCookie(tokenCookieName, idToken, options)

		if (useLocalStorage) {
			try {
				localStorage.setItem(tokenCookieName, idToken)
			} catch (e) {
				// Ignore any localStorage errors (not mission-critical)
			}
		}

		this.getTokenPayload(true)
	}

	setSubdomain(_subdomain?: string | null, useLocalStorage = false) {
		const { tenantCookieName } = AuthService
		const subdomain = _subdomain || this.getTenant()
		if (subdomain) {
			const expirationDate = this.getTokenExpirationDate()
			const options: AnyObj = {}
			if (expirationDate) {
				options.expires = expirationDate
			}
			setCookie(tenantCookieName, subdomain, options)
			if (useLocalStorage) {
				try {
					localStorage.setItem(tenantCookieName, subdomain)
				} catch (e) {
					// Ignore any localStorage errors (not mission-critical)
				}
			}
		}
	}

	unsetToken() {
		const { tokenCookieName, tenantCookieName } = AuthService
		deleteCookie(tokenCookieName)
		deleteCookie(tenantCookieName)
		try {
			localStorage.removeItem(tokenCookieName)
			localStorage.removeItem(tenantCookieName)
		} catch (e) {
			// Ignore any localStorage errors (not mission-critical)
		}
		this._tokenPayload = null
	}

	logout() {
		// Legacy -- resolve when possible
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { maybeClearCachedCognitoId } = require('./awsAuth')
		maybeClearCachedCognitoId()

		this.unsetToken()
	}

	shouldRedirect() {
		// @ts-expect-error -- Meticulous is a global variable
		if (window.Meticulous?.isRunningAsTest) return false
		const { hostname } = window.location
		const normalizedHostname = hostname.replace('www.', '')
		const appDomain = getAppDomain()
		const subdomain = this.getTenant()
		return !!(
			this.loggedIn() &&
			subdomain &&
			(appDomain === normalizedHostname ||
				normalizedHostname !== `${subdomain}.${appDomain}`)
		)
	}

	getRedirectUrl(customPath: string) {
		let path = customPath || this.getDefaultPath()
		if (path && window.location.pathname === path) {
			path = '/' // guarantees we don't redirect to same page (just in case)
		}
		return this.getClientUrl() + path
	}

	performRedirect(customPath: string) {
		window.location.href = this.getRedirectUrl(customPath)
	}

	/***********
	 API METHODS
	 ***********/
	logoutAndRefresh() {
		this.logout()
		setTimeout(() => window.location.reload(), 1800)
		toast('Session expiration detected, please log in again', 2000)
	}

	checkUnauthorized(error: { status?: number }) {
		const authError = error.status === 401
		if (authError) {
			this.logoutAndRefresh()
		}
	}

	/***********
	 IMPERSONATION
	 ***********/

	setSyntheticToken(token: SyntheticToken) {
		this._syntheticToken = token
	}

	setSyntheticPermissions(permissionNames: string[]) {
		this._syntheticToken = {
			...this.syntheticToken,
			permissions: permissionNames,
		}
	}

	get syntheticToken(): SyntheticToken | undefined {
		return this._syntheticToken || (getImpersonatedUser() || {}).syntheticToken
	}
}

export default new AuthService()
