import decode from 'jwt-decode'

export function decodeToken(token) {
	return decode(token)
}

export function getTokenExpirationDate(payload) {
	if (!payload.exp) {
		return null
	}

	const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
	date.setUTCSeconds(payload.exp)
	return date
}

export function isTokenExpired(payload) {
	const date = getTokenExpirationDate(payload)
	const offsetSeconds = 0
	if (date === null) {
		return false
	}
	return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
}
