import { User } from '@/types/entities'
import config from '@/utils/cgConfig'
import auth, { SyntheticToken } from 'authService'
import _ from 'missingjs'

const { ENV_NAME } = config

type PermissionOverride = {
	id: number
	name: string
}
export interface ImpersonatedUserData
	extends Partial<Pick<SyntheticToken, 'view_mode' | 'roles'>> {
	id: number
	// eslint-disable-next-line camelcase
	team_fund_ids?: number[]
	companyIds?: number[]
	permissions?: PermissionOverride[]
}

type StoredUser = Partial<User> &
	Pick<ImpersonatedUserData, 'team_fund_ids' | 'companyIds' | 'permissions'> & {
		syntheticToken: SyntheticToken
	}

const STORAGE_KEY = 'impersonatedUser'
const ALLOWED_ENVS = ['development', 'staging', 'sandbox']

export const permitImpersonation = (): boolean => {
	try {
		return (
			!!auth.actualStaffUser() && !!ENV_NAME && ALLOWED_ENVS.includes(ENV_NAME)
		)
	} catch (ex) {
		// Ignore error
	}
	return false
}

export const getImpersonatedUser = (): StoredUser | null => {
	if (!permitImpersonation()) return null
	try {
		const user = localStorage.getItem(STORAGE_KEY)
		const userObject = user && JSON.parse(user)
		if (_.get(userObject, 'id')) return userObject
	} catch (ex) {
		// Ignore localStorage errors
	}
	return null
}

export const setImpersonatedUser = (userObject: StoredUser): void => {
	if (!permitImpersonation()) return
	try {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(userObject))
	} catch (ex) {
		// Ignore localStorage errors
	}
}

export const clearImpersonatedUser = (): void => {
	try {
		localStorage.removeItem(STORAGE_KEY)
	} catch (ex) {
		// Ignore localStorage errors
	}
}
