declare global {
	interface Window {
		__CG_CONFIG__?: string
	}
}

interface Config {
	ANNOUNCEMENT_BASE64?: string
	ENV_NAME?: string
	APP_NAME?: string
	DISABLE_USER_ENV_CHECK?: boolean
}

const encoded = window.__CG_CONFIG__

let decoded: string | null | undefined = null
let config: Config = {}

try {
	decoded = encoded && window.atob(encoded)
	config = (decoded && JSON.parse(decoded)) || {}
} catch (e) {
	console.error(e) // eslint-disable-line no-console
}

export default config
