import React from 'react'
import './full_page_loader.scss'
import InlineSpinner from './inline_spinner'

const FullPageLoader: React.FC = () => (
	<div className='full-page-loader'>
		<InlineSpinner style={{ width: '2.5em', height: '2.5em' }} />
	</div>
)

export default FullPageLoader
