/* globals heap */

import config from '@/utils/cgConfig'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

const heapEnvironmentIds = {
	production: '3876840671',
	next: '3429528761',
	sandbox: '856610074',
	development: '1280096296',
}

const heapEnvironmentMap =
	{
		staging: heapEnvironmentIds.development,
		next: heapEnvironmentIds.next,
		sandbox: heapEnvironmentIds.sandbox,
		dev: heapEnvironmentIds.development,
		production: heapEnvironmentIds.production,
		test: heapEnvironmentIds.development,
	}[config?.ENV_NAME] || heapEnvironmentIds.development

function loadCanny() {
	if (typeof Canny === 'undefined') {
		!(function (w, d, i, s) {
			function l() {
				if (!d.getElementById(i)) {
					const f = d.getElementsByTagName(s)[0],
						e = d.createElement(s)
					;(e.type = 'text/javascript'),
						(e.async = !0),
						(e.src = 'https://canny.io/sdk.js'),
						f.parentNode.insertBefore(e, f)
				}
			}
			if ('function' !== typeof w.Canny) {
				const c = function () {
					c.q.push(arguments)
				}
				;(c.q = []),
					(w.Canny = c),
					'complete' === d.readyState
						? l()
						: w.attachEvent
						? w.attachEvent('onload', l)
						: w.addEventListener('load', l, !1)
			}
		})(window, document, 'canny-jssdk', 'script')
	}
}

function allowHeapInDevelopment() {
	const ALLOW_HEAP_IN_DEV = false
	return (
		ALLOW_HEAP_IN_DEV ||
		['production', 'sandbox', 'next'].includes(config?.ENV_NAME)
	)
}

function loadHeap() {
	if (typeof heap === 'undefined' && allowHeapInDevelopment()) {
		;(window.heap = window.heap || []),
			(heap.load = function (e, t) {
				;(window.heap.appid = e), (window.heap.config = t = t || {})
				const r = document.createElement('script')
				;(r.type = 'text/javascript'),
					(r.async = !0),
					(r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`)
				const a = document.getElementsByTagName('script')[0]
				a.parentNode.insertBefore(r, a)
				for (
					// Legacy -- resolve when possible
					// eslint-disable-next-line no-shadow
					let n = function (e) {
							return function () {
								heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
							}
						},
						p = [
							'addEventProperties',
							'addUserProperties',
							'clearEventProperties',
							'identify',
							'resetIdentity',
							'removeEventProperty',
							'setEventProperties',
							'track',
							'unsetEventProperty',
						],
						o = 0;
					o < p.length;
					o++
				)
					heap[p[o]] = n(p[o])
			})
		heap.load(heapEnvironmentMap)
	}
}

async function loadMeticulous() {
	if (!['production', 'sandbox', 'next', 'staging'].includes(config?.ENV_NAME))
		await tryLoadAndStartRecorder({
			projectId: 'sDMykJPiRYKbU958q04fAnkNDzP2O77WaTxl6YuD',
			isProduction: false,
		})
}

async function loadScripts() {
	await loadMeticulous()
	loadCanny()
	loadHeap()
}

loadScripts()
