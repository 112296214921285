import { captureExceptionWithOpts } from '@/utils/sentryUtils'
import React from 'react'

import { ErrorBoundary } from 'react-error-boundary'

import './app_boundary.scss'

const handleError = (error: Error, info: React.ErrorInfo) => {
	captureExceptionWithOpts(error, { extra: info })
}

const reloadLink = (
	<a onClick={() => window.location.reload()}>reload the page</a>
)

const ErrorFallback = ({ error }) => {
	const isLoadingError = error?.name === 'ChunkLoadError'

	const errorContent = isLoadingError ? (
		<p>
			Could not load the application. Please check your network connection and{' '}
			{reloadLink}.
		</p>
	) : (
		<p>
			Please {reloadLink} or{' '}
			<a href='mailto:support@chronograph.pe'>contact support</a> for further
			assistance.
		</p>
	)
	return (
		<div className='app-boundary-container'>
			<i className='material-icons'>error_outline</i>
			<h3>Apologies, something went wrong.</h3>
			{errorContent}
		</div>
	)
}

const AppBoundary: React.FC = ({ children }) => (
	<ErrorBoundary onError={handleError} FallbackComponent={ErrorFallback}>
		{children}
	</ErrorBoundary>
)

export default AppBoundary
