import auth from 'authService'
import _ from 'missingjs'
import config from './cgConfig'
import { installSentry } from './sentryUtils'

const gitVersion = window.__CG_VERSION__

const { ENV_NAME, APP_NAME } = config

const environment = ENV_NAME && _.toTitleCase(ENV_NAME)

const options = {
	dsn: process.env.SENTRY_URL || process.env.RAVEN_URL,
	release: gitVersion,
	environment,
}

const tags = {
	app_name: APP_NAME,
	tenant: auth.getTenant(),
	'user.client_type': auth.isGp() ? 'gp' : 'lp',
	'user.full_access': auth.hasFullAccess(),
	'user.input_user': auth.isInputUser(),
	'user.is_admin': auth.isAdmin(),
	'user.is_gp': auth.isGp(),
	'user.is_staff': auth.actualStaffUser(),
	'user.is_user': auth.isUser(),
}

installSentry(options, tags)
