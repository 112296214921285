import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const getEnableSessionReplay = tags => {
	if (process.env.NODE_ENV !== 'production') return false
	if (!tags?.tenant || ['qa-gp', 'qa-lp'].includes(tags?.tenant)) return false
	return process.env.SENTRY_ENABLE_SESSION_REPLAY === 'true'
}

export const installSentry = (options, tags) => {
	const enableSessionReplay = getEnableSessionReplay(tags)
	Sentry.init({
		...options,
		enabled: process.env.NODE_ENV === 'production',
		integrations: [
			new Integrations.BrowserTracing({
				tracePropagationTargets: [/^\//],
			}),
			...(enableSessionReplay
				? [
						Sentry.replayIntegration({
							blockAllMedia: false,
							maskAllText: false,
							minReplayDuration: 10000,
						}),
				  ]
				: []),
		],
		replaysSessionSampleRate: enableSessionReplay ? 1.0 : 0,
		replaysOnErrorSampleRate: enableSessionReplay ? 1.0 : 0,
		tracesSampleRate:
			parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE) || undefined,
	})
	Sentry.setTags(tags)
}

export const unsetExtraContextKeys = keys => {
	if (!keys) return
	keys.forEach(k => Sentry.setExtra(k, undefined))
}

export const unsetTagsContextKeys = keys => {
	if (!keys) return
	keys.forEach(k => Sentry.setTag(k, undefined))
}

// Legacy handlers -- maintain prior function signature
// to avoid more extensive refactors

const captureLegacy = (handlerFunc, evt, sentryOpts) => {
	Sentry.withScope(scope => {
		const { extra, tags } = sentryOpts || {}
		if (extra) scope.setExtras(extra)
		if (tags) scope.setTags(tags)
		handlerFunc(evt)
	})
}

export const captureExceptionWithOpts = (exc, sentryOpts) => {
	captureLegacy(Sentry.captureException, exc, sentryOpts)
}

export const captureMessageWithOpts = (msg, sentryOpts) => {
	captureLegacy(Sentry.captureMessage, msg, sentryOpts)
}
