import 'datejs'
import React from 'react'
import { render } from 'react-dom'
import './utils/scriptLoader'
import './utils/sentryConfig'

import { CO_ADMIN_BASE_ROUTE } from '@/constants/globalConstants'
import { loadableWithRetry } from '@/utils/loadable'
import { LocationProvider, Router } from '@reach/router'
import { hot } from 'react-hot-loader/root'

import AppBoundary from '@/components/errors/app_boundary'
import FullPageLoader from '@/components/spinners/full_page_loader'

// Code split application bundles
const MainApp = loadableWithRetry(() => import('./main_app'), {
	fallback: <FullPageLoader />,
})
const V3App = loadableWithRetry(() => import('../v3/index'), {
	fallback: <FullPageLoader />,
})

const AppRouter = () => (
	<AppBoundary>
		<LocationProvider>
			<Router>
				<V3App path={`${CO_ADMIN_BASE_ROUTE}/*`} />
				<MainApp default />
			</Router>
		</LocationProvider>
	</AppBoundary>
)

const HotApp = hot(AppRouter)

render(<HotApp />, document.getElementById('root'))
