export const paths = {
	privacyPolicy: 'https://www.chronograph.pe/legal/privacy-policy/',
	termsOfService: 'https://www.chronograph.pe/legal/website-terms-of-use/',
}

export const legalName = 'Chronograph, LLC'

export const address = {
	street: 'Ten Grand Street | Floor 10',
	city: 'Brooklyn',
	state: 'NY',
	zip: '11249',
	country: 'US',
}

const phone = {
	e164: '+16467010006',
	formatted: '+1 646.701.0006',
}

export const contacts = {
	main: {
		email: 'info@chronograph.pe',
		phone,
		address,
	},
	support: {
		email: 'support@chronograph.pe',
		phone,
	},
}

export const socialAccounts = {
	linkedin: {
		title: 'LinkedIn',
		url: 'https://www.linkedin.com/company/chronograph',
	},
	github: {
		title: 'GitHub',
		url: 'https://github.com/chronograph-pe',
	},
	builtin: {
		title: 'BuiltIn',
		url: 'https://www.builtinnyc.com/company/chronograph',
	},
}
