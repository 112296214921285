import moment, { locale, unitOfTime } from 'moment'
import 'moment/locale/en-gb'
// eslint-disable-next-line import/no-duplicates
import isValid from 'date-fns/isValid'
// eslint-disable-next-line import/no-duplicates
import parseISO from 'date-fns/parseISO'
import _ from 'missingjs'

const DATE_LOCALE_KEY = 'date_locale'

enum DateLocale {
	EnGb = 'en-GB',
	EnUs = 'en-US',
}
const FALLBACK_DATE_LOCALE = DateLocale.EnUs

export const setDateLocale = (
	localeKey: string = FALLBACK_DATE_LOCALE,
): void => {
	localStorage.setItem(DATE_LOCALE_KEY, localeKey)
	locale(localeKey)
}

export const getDateLocale = (): string =>
	localStorage.getItem(DATE_LOCALE_KEY) || FALLBACK_DATE_LOCALE

/* Date formats for specific locales */
export const SUPPORTED_DATE_FORMATS = {
	YEAR_MONTH_DAY: {
		[DateLocale.EnUs]: 'yyyy-MM-dd',
		[DateLocale.EnGb]: 'yyyy-MM-dd',
	},
	YEAR_MONTH_DAY_MOMENT: {
		[DateLocale.EnUs]: 'YYYY-MM-DD',
		[DateLocale.EnGb]: 'YYYY-MM-DD',
	},
	LONG_MONTH_WITH_YEAR: {
		[DateLocale.EnUs]: 'MMMM yyyy',
		[DateLocale.EnGb]: 'MMMM yyyy',
	},
	LONG_MONTH_WITH_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'MMMM YYYY',
		[DateLocale.EnGb]: 'MMMM YYYY',
	},
	LONG_DATE: {
		[DateLocale.EnUs]: 'MMMM d, yyyy',
		[DateLocale.EnGb]: 'd MMMM yyyy',
	},
	LONG_DATE_MOMENT: {
		[DateLocale.EnUs]: 'MMMM D, YYYY',
		[DateLocale.EnGb]: 'D MMMM YYYY',
	},
	LONG_DATE_WITH_TIME_MOMENT: {
		[DateLocale.EnUs]: 'LL h:mm A',
		[DateLocale.EnGb]: 'LL h:mm A',
	},
	FULL_MONTH_DAY_ORDINAL_YEAR: {
		[DateLocale.EnUs]: 'MMMM Do, yyyy',
		[DateLocale.EnGb]: 'Do MMMM yyyy',
	},
	FULL_MONTH_DAY_ORDINAL_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'MMMM Do, YYYY',
		[DateLocale.EnGb]: 'Do MMMM YYYY',
	},
	ABBR_MONTH_DAY_YEAR: {
		[DateLocale.EnUs]: 'MMM. d, yyyy',
		[DateLocale.EnGb]: 'd MMM. yyyy',
	},
	ABBR_MONTH_DAY_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'MMM. D, YYYY',
		[DateLocale.EnGb]: 'D MMM. YYYY',
	},
	ABBR_MONTH_DAY_YEAR_HYPHEN: {
		[DateLocale.EnUs]: 'MMM-d-yyyy',
		[DateLocale.EnGb]: 'd-MMM-yyyy',
	},
	ABBR_MONTH_DAY_YEAR_HYPHEN_MOMENT: {
		[DateLocale.EnUs]: 'MMM-D-YYYY',
		[DateLocale.EnGb]: 'D-MMM-YYYY',
	},
	MONTH_DAY_YEAR: {
		[DateLocale.EnUs]: 'M/d/yyyy',
		[DateLocale.EnGb]: 'd/M/yyyy',
	},
	MONTH_DAY_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'M/D/YYYY',
		[DateLocale.EnGb]: 'D/M/YYYY',
	},
	MONTH_DAY_SHORT_YEAR: {
		[DateLocale.EnUs]: 'M/d/yy',
		[DateLocale.EnGb]: 'd/M/yy',
	},
	MONTH_DAY_SHORT_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'M/D/YY',
		[DateLocale.EnGb]: 'D/M/YY',
	},
	ABBR_MONTH_SHORT_YEAR: {
		[DateLocale.EnUs]: 'MMM-yy',
		[DateLocale.EnGb]: 'MMM-yy',
	},
	ABBR_MONTH_SHORT_YEAR_MOMENT: {
		[DateLocale.EnUs]: 'MMM-YY',
		[DateLocale.EnGb]: 'MMM-YY',
	},
	MONTH_DAY: {
		[DateLocale.EnUs]: 'MMMM d',
		[DateLocale.EnGb]: 'd MMMM',
	},
	MONTH_DAY_MOMENT: {
		[DateLocale.EnUs]: 'MMMM D',
		[DateLocale.EnGb]: 'D MMMM',
	},
}

type DateFormat = keyof typeof SUPPORTED_DATE_FORMATS
type DateFormats = { [key in DateFormat]: key }

/* Used as a way to lookup date formats */
export const DATE_FORMATS = Object.keys(SUPPORTED_DATE_FORMATS).reduce(
	(acc, key) => {
		acc[key] = key
		return acc
	},
	{} as DateFormats,
)

/* Retrieves date format via DATE_FORMATS key and users selected locale stored in localStorage */
export const getDateFormat = (format: DateFormat): string =>
	SUPPORTED_DATE_FORMATS[format][getDateLocale()]

/* This is used only when necessary to retrieve a format for a specific locale that is passed in */
export const getDateFormatForLocale = (
	format: DateFormat,
	// Legacy -- resolve when possible
	// eslint-disable-next-line @typescript-eslint/no-shadow
	locale: string,
): string => SUPPORTED_DATE_FORMATS[format][locale]

type DateArg = Date | string

export const minutesAgo = (date: DateArg): number =>
	moment().diff(date, 'minutes')

// Number of full 24-hour periods that have elapsed since `date`
export const daysAgo = (date: DateArg): number => moment().diff(date, 'days')

export const weeksAgo = (date: DateArg): number => moment().diff(date, 'weeks')

export const monthsAgo = (date: DateArg): number =>
	moment().diff(date, 'months')

export const yearsAgo = (date: DateArg): number => moment().diff(date, 'years')

export const isToday = (date: DateArg): boolean => moment().isSame(date, 'day')

export const isYesterday = (date: DateArg): boolean =>
	moment().subtract(1, 'days').isSame(date, 'day')

const agoText = (unit: string, count: number): string =>
	`${count} ${unit}${count === 1 ? '' : 's'} ago`

export const happenedAtText = (date?: DateArg): string => {
	if (!date || !moment(date).isValid) return ''

	// within last hour
	const minsAgo = minutesAgo(date)
	if (minsAgo === 0) return 'Just now'
	if (minsAgo < 60) return agoText('minute', minsAgo)

	// today or yesterday
	if (isToday(date)) return `Today at ${moment(date).format('h:mma')}`
	if (isYesterday(date)) return `Yesterday at ${moment(date).format('h:mma')}`

	const months = monthsAgo(date)

	if (months > 11) {
		const years = Math.max(1, yearsAgo(date))
		return agoText('year', years)
	}

	if (months >= 1) {
		return agoText('month', months)
	}

	const weeks = weeksAgo(date)

	if (weeks >= 1) {
		return agoText('week', weeks)
	}

	// beyond yesterday
	// (because `daysAgo` bases off of 24 hr periods, you could have something that happened
	// two calendar days ago still return a value of 1. Because anything that actually happened
	// yesterday will get picked up in the above logic, the below is floored at 2 days ago).
	return `${Math.max(2, daysAgo(date))} days ago`
}

export const subtractTime = (
	date: DateArg,
	unit: unitOfTime.DurationConstructor,
	amount: number,
	format?: string,
): string => {
	if (!moment(date).isValid) return ''
	return moment(date)
		.subtract(amount, unit)
		.format(format || 'll')
}

export const timestampDiff = (
	date1: DateArg,
	date2: DateArg,
	unit: unitOfTime.DurationConstructor,
): number | null => {
	if (!moment(date1).isValid || !moment(date2).isValid) return null
	return moment(date1).diff(date2, unit)
}

export const toDate = (value?: DateArg | null): Date | null => {
	const parsedValue = typeof value === 'string' ? parseISO(value) : value
	return parsedValue && isValid(parsedValue) ? parsedValue : null
}

// ==================================================
// PARSE EXCEL SERIAL DATE INTEGER
// ==================================================

const getDateFromExcelDate = (excelDate: number): Date => {
	// excelDate 1 <==> January 1, 1900, so use day prior as reference point
	const excelEpoch = Date.UTC(1899, 11, 31) // months are zero-indexed
	// Adjust for Excel's incorrect leap year 1900
	const daysOffset = excelDate > 60 ? excelDate - 1 : excelDate
	// Convert days to milliseconds
	const milliseconds = daysOffset * 24 * 60 * 60 * 1000
	// Return timezone-agnostic date (otherwise this would return a different
	// date for users behind UTC time, e.g. in the US, and users ahead of UTC,
	// e.g. in the UK). To provide an example:
	//
	// excelDate = 1
	// for NY user:
	// 		dateForLocale = Sun Dec 31 1899 19:00:00 GMT-0500
	// 		return value = Mon Jan 01 1900 00:00:00 GMT-0500
	// for UK user:
	// 		dateForLocale = Mon Jan 01 1900 01:00:00 GMT+0100
	// 		return value = Mon Jan 01 1900 00:00:00 GMT+0100
	const dateForLocale = new Date(excelEpoch + milliseconds)
	return new Date(
		dateForLocale.getUTCFullYear(),
		dateForLocale.getUTCMonth(),
		dateForLocale.getUTCDate(),
	)
}

export const parseExcelDate = (
	dateValue?: string | number,
	minDate?: Date,
	maxDate?: Date,
): Date | null => {
	if (
		typeof dateValue !== 'number' ||
		!Number.isInteger(dateValue) ||
		dateValue < 1 // Excel dates start at 1
	)
		return null
	const date = getDateFromExcelDate(dateValue)
	return (!minDate || date > minDate) && (!maxDate || date < maxDate)
		? date
		: null
}

// ==================================================
// PARSE FINANCIAL DATE ABBREVIATION (eg. 'Q1 2024')
// ==================================================

const QUARTER_DATE_INDICATORS = ['quarter', 'fq', 'q']
const HALF_DATE_INDICATORS = ['half', 'fh', 'h']
const YEAR_DATE_INDICATORS = ['fye', 'fy', 'ye', 'y']

const stripSpecialChars = (dateString: string) =>
	dateString.replace(/[^0-9a-zA-Z]/g, '')

// This is the tricky part of the algorithm -- trying to parse which numbers in the
// date string represent the period, and which represent the year. The complicating case
// is when the dateString does not include special characters (eg. Q42024). Ignoring
// this case, we could simply split the string by non-alphanumeric characters, find the element
// in the resulting array that contains the periodIndicator, and know that the number
// in that element is the period. However, due to the edge case case mentioned above,
// we need to rely on the location and length of the periodIndicator within the dateString,
// then infer the period and year from there
const getDateComponents = (dateString: string, periodIndicator: string) => {
	const alphanumericString = stripSpecialChars(dateString).toLowerCase()
	const indexOfIndicator = alphanumericString.indexOf(periodIndicator)
	const indicatorLength = periodIndicator.length
	// Add one to account for periodIndicator + period number.
	// eg. 'Q4' has periodIndicator 'Q' and period number '4'
	// for total length of 2
	const indicatorLengthWithPeriod = indicatorLength + 1

	// Check if period comes first in date string, eg. 'Q4 2024'. Comparison
	// against 2 because the start of the periodIndicator could either be at
	// index 0 ('Q4 2024') or at index 1 ('4Q 2024')
	if (indexOfIndicator < 2)
		return {
			period: alphanumericString
				.slice(0, indicatorLengthWithPeriod)
				.replace(/\D/g, ''),
			year: alphanumericString.slice(indicatorLengthWithPeriod),
		}

	// Otherwise period comes second in the date string, eg.'2024 Q1'. In the case that
	// the periodIndicator is at the very end of the string, eg. '2024 1Q', we need to
	// adjust the slice index to keep the '1' and 'Q' together
	const indexOfIndicatorAndPeriod =
		indexOfIndicator + indicatorLength === alphanumericString.length
			? indexOfIndicator - 1
			: indexOfIndicator
	return {
		period: alphanumericString
			.slice(indexOfIndicatorAndPeriod)
			.replace(/\D/g, ''),
		year: alphanumericString.slice(0, indexOfIndicatorAndPeriod),
	}
}

const adjustFiscalMonth = (month: number, fiscalYearEnd?: string | null) => {
	const fiscalMonth = new Date(fiscalYearEnd ?? 'December 31').getMonth()
	return (month + fiscalMonth + 1) % 12
}

type ParseFinancialDate = (data: {
	dateString: string
	fiscalYearEnd?: string | null
	periodIndicator: string
}) => string | null

const parseQuarterEndDate: ParseFinancialDate = ({
	dateString,
	fiscalYearEnd,
	periodIndicator,
}) => {
	const { period, year } = getDateComponents(dateString, periodIndicator)
	const periodInt = Number(period)
	const month =
		(periodInt === 1 && 2) ||
		(periodInt === 2 && 5) ||
		(periodInt === 3 && 8) ||
		(periodInt === 4 && 11) ||
		null
	const fullYear =
		(year.length === 2 && `20${year}`) || (year.length === 4 && year) || null
	if (!month || !fullYear) return null
	return _.monthEnd(
		new Date(Number(fullYear), adjustFiscalMonth(month, fiscalYearEnd), 1),
	)
}

const parseHalfEndDate: ParseFinancialDate = ({
	dateString,
	fiscalYearEnd,
	periodIndicator,
}) => {
	const { period, year } = getDateComponents(dateString, periodIndicator)
	const periodInt = Number(period)
	const month = (periodInt === 1 && 5) || (periodInt === 2 && 11) || null
	const fullYear =
		(year.length === 2 && `20${year}`) || (year.length === 4 && year) || null
	if (!month || !fullYear) return null
	return _.monthEnd(
		new Date(Number(fullYear), adjustFiscalMonth(month, fiscalYearEnd), 1),
	)
}

const parseYearEndDate: ParseFinancialDate = ({
	dateString,
	fiscalYearEnd,
}) => {
	const year = dateString.replace(/\D/g, '')
	const fullYear =
		(year.length === 2 && `20${year}`) || (year.length === 4 && year) || null
	if (!fullYear) return null
	return _.monthEnd(
		new Date(Number(fullYear), adjustFiscalMonth(11, fiscalYearEnd), 1),
	)
}

export const parseFinancialDateString = (
	dateString?: string | null,
	fiscalYearEnd?: string | null,
): string | null => {
	if (!dateString) return null
	const lcDateString = dateString.toLowerCase()
	// Strip all non alphabetic characters
	const periodIndicatorTest = lcDateString.replace(/[^a-zA-Z]/g, '')

	const parseFn =
		(QUARTER_DATE_INDICATORS.includes(periodIndicatorTest) &&
			parseQuarterEndDate) ||
		(HALF_DATE_INDICATORS.includes(periodIndicatorTest) && parseHalfEndDate) ||
		(YEAR_DATE_INDICATORS.includes(periodIndicatorTest) && parseYearEndDate) ||
		null

	return (
		parseFn?.({
			dateString: lcDateString,
			fiscalYearEnd,
			periodIndicator: periodIndicatorTest,
		}) || null
	)
}

// ==================================================
// PARSE PARTICULAR DATE FORMATS
// ==================================================

type LongDateFormats = {
	regex: RegExp
	momentFormat: string
	period: moment.unitOfTime.StartOf
	transform?(v: string): string
}
type DateFormatsByKey = Record<string, LongDateFormats>
const dateFormatsByKey: DateFormatsByKey = {
	MMMYY: {
		regex: /[A-Za-z]{3,9}[\d]{2}\b/,
		momentFormat: 'MMMYY',
		period: 'month',
		transform: v => {
			return `${v.slice(0, 3)} ${v.slice(-2)}`
		},
	},
	MMMYYYY: {
		regex: /[A-Za-z]{3,9}[\d]{4}\b/,
		momentFormat: 'MMMYYYY',
		period: 'month',
		transform: v => {
			return `${v.slice(0, 3)} ${v.slice(-4)}`
		},
	},
	'MMM-YY': {
		regex: /[A-Za-z]{3,9}[-\s][\d]{2}\b/,
		momentFormat: 'MMM-YY',
		period: 'month',
		transform: v => {
			return `${v.slice(0, 3)} ${v.slice(-2)}`
		},
	},
	'MMM-YYYY': {
		regex: /[A-Za-z]{3,9}[-\s][\d]{4}/,
		momentFormat: 'MMM-YYYY',
		period: 'month',
		transform: v => {
			return `${v.slice(0, 3)} ${v.slice(-4)}`
		},
	},
	'QQ-YY': {
		regex: /[Q][\d]-[\d]{2}\b/,
		momentFormat: 'Q-YY',
		transform: v => v.replace('Q', ''),
		period: 'quarter',
	},
	'QQ-YYYY': {
		regex: /[Q][\d]-[\d]{4}/,
		momentFormat: 'Q-YYYY',
		transform: v => v.replace('Q', ''),
		period: 'quarter',
	},
	YYYY: {
		regex: /[\d]{4}/,
		momentFormat: 'YYYY',
		period: 'year',
	},
}

const applyDateTemplate = (dateStr: string, dateKey: string): string | null => {
	const dateFormat = dateFormatsByKey[dateKey]
	const { regex, momentFormat, period, transform } = dateFormat || {}
	const matches = dateStr && dateStr.match(regex)
	// eslint-disable-next-line eqeqeq
	if (matches && matches[0] == dateStr) {
		const m = moment(transform ? transform(dateStr) : dateStr, momentFormat)
		return (m && m.endOf(period).format('YYYY-MM-DD')) || null
	}
	return null
}

export const parseDateTemplates = (dateString?: string | null): string | null =>
	dateString
		? Object.keys(dateFormatsByKey).reduce((date, dateKey) => {
				return date || applyDateTemplate(dateString, dateKey)
		  }, null)
		: null

// ==================================================
// PARSE STANDARD DATE STRING (eg. '2-28-2024' or 'Feb, 24')
// ==================================================

export const parseDateString = (
	dateString?: string | number,
	dateLocale?: string | null,
) => {
	if (typeof dateString !== 'string') return null
	// Split by all non-alphanumeric characters, filter out resulting empty strings
	const dateComponents = dateString.split(/\W/).filter(dc => !!dc)
	if (![2, 3].includes(dateComponents.length)) return null
	if (
		// Indicates non-numeric text within dateString (eg. 'Feb, 2024'), don't need to consider locale
		dateComponents.some(dc => isNaN(Number(dc))) ||
		// Indicates a 'MM-YYYY' or 'YYYY-MM' format, don't need to consider locale
		dateComponents.length === 2 ||
		// Indicates a 'YYYY-MM-DD' date format, don't need to consider locale
		dateComponents[0].length === 4 ||
		!dateLocale
	) {
		return Date.parse(dateString) || null
	}

	let day: string, month: string, year: string
	if (dateLocale === DateLocale.EnGb) {
		// Semicolon coming from prettier
		;[day, month, year] = dateComponents
	} else if (dateLocale === DateLocale.EnUs) {
		// Semicolon coming from prettier
		;[month, day, year] = dateComponents
	} else {
		return null
	}
	if (year.length === 2) year = `20${year}`

	const date = new Date(Number(year), Number(month) - 1, Number(day))
	return isNaN(date.getTime()) ? null : date
}
