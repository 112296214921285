import { AUTH0_DOMAIN } from '@/constants/auth_constants'
import auth from 'authService'
import AWS from 'aws-sdk/global'

export const maybeClearCachedCognitoId = () => {
	if (AWS.config.credentials && AWS.config.credentials.clearCachedId) {
		AWS.config.credentials.clearCachedId()
	}
}

const getOidcProviderFromToken = () => {
	const jwtPayload = auth.getTokenPayload()
	const { iss } = jwtPayload || {}
	const issMatches = iss && iss.match(/^(?:https:\/\/)?([^/]+)\/?/)
	return issMatches && issMatches[1]
}

const awsAuth = (IdentityPoolId, region) => {
	maybeClearCachedCognitoId()
	if (auth.loggedIn()) {
		const oidcProvider = getOidcProviderFromToken() || AUTH0_DOMAIN
		AWS.config.credentials = new AWS.CognitoIdentityCredentials(
			{
				IdentityPoolId,
				Logins: {
					[oidcProvider]: auth.getToken(),
				},
			},
			{ region },
		)
	}
}

export default awsAuth
