import React from 'react'

const InlineSpinner = ({ style = {} }) => (
	<div
		className='Select-loading'
		style={{
			width: '1.25em',
			height: '1.25em',
			...style,
		}}
	/>
)

export default InlineSpinner
