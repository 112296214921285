import { getAppDomain, getHostElems } from 'domainUtils'

export const setCookie = (key, value, options = {}) => {
	const defaults = {
		path: '/',
		domain: `.${getAppDomain()}`,
	}
	document.cookie =
		['expires', 'path', 'domain'].reduce((cookieStr, param) => {
			let paramValue = options[param] || defaults[param]
			if (paramValue) {
				if (paramValue instanceof Date) {
					paramValue = paramValue.toGMTString()
				}
				return `${cookieStr}${param}=${paramValue};`
			}
			return cookieStr
		}, `${key}=${value};`) +
		(process.env.NODE_ENV === 'production' ? 'secure;' : '')
}

export const getCookie = name => {
	const decodedCookieStr = decodeURIComponent(document.cookie)
	const matches = decodedCookieStr.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)
	return matches ? matches.pop() : ''
}

export const deleteCookie = (name, rootDomains = true) => {
	if (getCookie(name)) {
		setCookie(name, '', { expires: -1 })

		if (rootDomains) {
			const hostElems = getHostElems()
			const numDomains = hostElems.length
			if (numDomains > 3) {
				;[...Array(numDomains - 3)].forEach((_, i) => {
					const domain = `.${
						hostElems
							.slice(i + 2)
							.join('.')
							.split(':')[0]
					}`
					setCookie(name, '', { expires: -1, domain })
				})
			}
		}
	}
}

export const getCookieOrLocalStorage = name => {
	const cookie = getCookie(name)
	if (cookie) return cookie
	try {
		return localStorage.getItem(name) || ''
	} catch (e) {
		return ''
	}
}
