import loadable from '@loadable/component'
import * as Sentry from '@sentry/react'

const isDevelopment = process.env.NODE_ENV === 'development'

type Options = {
	retries?: number
	interval?: number
	exponentialBackoff?: boolean
}

// See https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
function retry<R>(
	fn: () => Promise<R>,
	{ retries = 3, interval = 500, exponentialBackoff = true }: Options = {},
): Promise<R> {
	return new Promise<R>((resolve, reject) => {
		fn()
			.then(resolve)
			.catch(error => {
				setTimeout(() => {
					// Avoid swallowing errors in development
					if (isDevelopment) throw error
					if (retries === 1) {
						Sentry.captureException(error)
						reject(error)
						return
					}

					// Passing on "reject" is the important part
					retry(fn, {
						retries: retries - 1,
						interval: exponentialBackoff ? interval * 2 : interval,
					}).then(resolve, reject)
				}, interval)
			})
	})
}

export function loadableWithRetry<R>(
	fn: () => Promise<R>,
	loadableProps,
): Promise<R> {
	return loadable(() => retry(fn), loadableProps)
}
