export const fxHash = {
	USD: '$USD',
	AED: 'د.إ AED',
	ARS: '$ARS',
	AUD: '$AUD',
	BDT: '৳BDT',
	BGN: 'лвBGN',
	BRL: '$BRL',
	CAD: '$CAD',
	CHF: 'SFrCHF',
	CLP: '$CLP',
	CNY: '¥CNY',
	COP: '$COP',
	CZK: 'KčCZK',
	DKK: 'KrDKK',
	DZD: 'دجDZD',
	EGP: 'E£EGP',
	EUR: '€EUR',
	GBP: '£GBP',
	HKD: '$HKD',
	HRK: 'knHRK',
	HUF: 'FtHUF',
	IDR: 'RpIDR',
	ILS: '₪ILS',
	INR: '₹INR',
	JOD: 'د.أ JOD',
	JPY: '¥‎JPY',
	KES: 'KShKES',
	KRW: '₩KRW',
	KZT: '₸KZT',
	LKR: 'රුLKR',
	MAD: 'د. مMAD',
	MMK: 'KsMMK',
	MXN: '$MXN',
	MYR: 'RmMYR',
	NGN: '₦NGN',
	NOK: 'krNOK',
	NZD: '$NZD',
	PEN: 'S/PEN',
	PHP: '₱PHP',
	PLN: 'złPLN',
	RON: 'leiRON',
	RUB: '₽RUB',
	SAR: 'ر.سSAR',
	SEK: 'krSEK',
	SGD: '$SGD',
	THB: '฿THB',
	TND: 'د.تTND',
	TRY: '₺TRY',
	TWD: '$TWD',
	UAH: '₴UAH',
	VND: '₫VND',
	XOF: 'CFAXOF',
	ZAR: 'RZAR',
}

export const defaultCurrencyOptions = Object.keys(fxHash).map(fx => ({
	label: fxHash[fx],
	value: fx,
}))

export const currencyOptionsByKey = Object.keys(fxHash).reduce((rv, k) => {
	rv[k] = {
		label: fxHash[k],
		value: k,
	}
	return rv
}, {})

export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_FUND_UNITS = 'millions'
export const DEFAULT_COMMITMENT_UNITS = 'actual'

export const PORTFOLIO = {
	current_reporting_date: {
		input: 'date',
		props: {
			validations: [{ presence: true }],
			onlyQuarterEnds: true,
			openToLastQuarterEnd: true,
			maxDate: new Date(),
			showDisabledMonthNavigation: true,
			showMonthDropdown: false,
			showYearDropdown: false,
		},
	},
}

// units used in several places to abbreviate currency values based on magnitude
export enum MagnitudeUnits {
	Actual = 'actual',
	Thousands = 'thousands',
	TenThousands = 'ten thousands',
	HundredThousands = 'hundred thousands',
	Millions = 'millions',
	TenMillions = 'ten millions',
	Billions = 'billions',
}

export const UNIT_OPTIONS = ['Actual', 'Thousands', 'Millions', 'Billions']

export const UNIT_OPTIONS_LOWERCASE = UNIT_OPTIONS.map(label =>
	label.toLowerCase(),
)

export const unitsOptionsByKey = UNIT_OPTIONS.reduce((rv, label) => {
	const k = label.toLowerCase()
	rv[k] = {
		label,
		value: k,
	}
	return rv
}, {})

export const CO_ADMIN_BASE_ROUTE = '/portco'
